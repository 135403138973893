angular
  .module('events.components.eventRegister', [
    'angularjs-dropdown-multiselect',
    'events.services.eventRegistersService',
    'events.services.eventsService',
    'events.services.eventRegisterTransportService',
    'group.controllers.multiPersonPickerPopupController',
    'pascalprecht.translate',
    'person.services.personPickerService',
    'shared.components.sbColumnHeader',
    'shared.components.sbIcon',
    'shared.components.sbOnOffSwitch',
    'shared.components.sbAvatar',
    'shared.components.sbModalHeader',
    'shared.components.sbSubmitButton',
    'shared.services.cachedLookupService',
    'shared.services.registerStateService',
    'shared.services.simpleAlertService',
    'shared.services.fileService',
    'shared.services.deviceService',
    'reception.services.registerFileService',
    'shared.services.searchService',
    'shared.services.blobStoragePhotosService',
    'teams.services.teamService',
    'shared.constants',
  ])
  .component('eventRegister', {
    bindings: {
      eventId: '<',
      showAbsentColumn: '<',
      onRegisterUpdated: '&',
      onAttendeesAddedToEvent: '&',
      isExternalCanViewProfile: '<',
      isExternal: '<',
      isOrganisationAdmin: '<',
      isTeacher: '<',
      isReadOnly: '<',
      routeStopId: '<',
      lastRefreshed: '<',
    },
    templateUrl: '/Scripts/app/events/components/events-register.template.html',
    controller: [
      '$uibModal',
      '$window',
      '$filter',
      '$translate',
      '$timeout',
      'eventRegistersService',
      'eventsService',
      'simpleAlertService',
      'personPickerService',
      'cachedLookupService',
      'registerStateService',
      'eventRegisterTransportService',
      'fileService',
      'registerFileService',
      'personService',
      'searchService',
      'blobStoragePhotosService',
      'deviceService',
      function (
        $uibModal,
        $window,
        $filter,
        $translate,
        $timeout,
        eventRegistersService,
        eventsService,
        simpleAlertService,
        personPickerService,
        cachedLookupService,
        registerStateService,
        eventRegisterTransportService,
        fileService,
        registerFileService,
        personService,
        searchService,
        blobStoragePhotosService,
        deviceService
      ) {
        this.loadFailureMessage = '';
        this.eventRegisterTransportService = eventRegisterTransportService;
        this.isSchoolboundTransportRegister = false;

        this.$onInit = function () {
          // Handle app webview to show add pupils content
          this.deviceService = deviceService;
          this.isModalOnly = this.deviceService.getShowModalType();

          this.eventTimeOrderingOptions = [];
          this.eventTimeOrderingOptions.push({ label: 'SB_None', value: '' });
          this.eventTimeOrderingOptions.push({ label: 'SB_Next_Event_Start_Time', value: 'N-ASC' });
          this.eventTimeOrderingOptions.push({ label: 'SB_Previous_Event_End_Time', value: 'P-ASC' });
          this.eventTimeOrderingOptions.push({ label: 'SB_Next_Event_Start_Time_Desc', value: 'N-DESC' });
          this.eventTimeOrderingOptions.push({ label: 'SB_Previous_Event_End_Time_Desc', value: 'P-DESC' });
          this.eventTimeOrder = this.eventTimeOrderingOptions[0];

          this.mode = $window.innerWidth > 500 && !this.isReadOnly ? 'd' : 'q';
          this.xsShowSearch = false;
          this.registerActionedId = 0;
          this.registerInfoId = 0;

          this.selectYearsText = $filter('translate')('SB_Select_Years');
          //configure the multi-select drop down control
          this.dropdownSettings = {
            buttonClasses: 'btn btn-default pl-2 pr-2',
            checkBoxes: true,
            keyboardControls: true,
            showUncheckAll: false,
            showCheckAll: false,
            smartButtonMaxItems: 3,
            smartButtonTextConverter: function (itemText) {
              return itemText;
            },
          };
        };

        this.canAdd = false;

        this.isTransportEvent = function () {
          return this.selectedRegister && this.eventRegisterTransportService.isTransportEvent(this.selectedRegister);
        }.bind(this);

        this.isRouteTransportEvent = function () {
          return this.eventRegisterTransportService.isRouteTransportEvent(this.selectedRegister);
        }.bind(this);

        this.moveToExpected = function (item) {
          eventRegistersService.moveToExpected(item.calendarEventAttendeeId).then(
            function (data) {
              item.transportAttendeeState = data.calendarEventAttendeeTransportStatusId;
              item.transportIsExpected = data.isExpected;
              this.arrangeAttendeeSections();
              resetSubmitButtons();
            }.bind(this)
          ),
            function () {
              simpleAlertService.errorAlert();
              resetSubmitButtons();
            };
        }.bind(this);

        this.transportSections = this.eventRegisterTransportService.transportSections;

        $translate.onReady().then(
          function () {
            this.selectYearsText = $translate.instant('SB_Select_Years');
          }.bind(this)
        );

        this.filter = {
          selectedSchoolYearNames: [],
          text: '',
        };

        this.selectedSchoolYearNameIds = [];

        this.yearSelected = function (year) {
          this.filter.selectedSchoolYearNameIds.push(year.id);
        }.bind(this);

        this.yearDeselected = function (year) {
          for (var i = 0; i < this.filter.selectedSchoolYearNameIds.length; i++) {
            if (this.filter.selectedSchoolYearNameIds[i] == year.id) {
              this.filter.selectedSchoolYearNameIds.splice(i, 1);
              break;
            }
          }
        }.bind(this);

        this.selectedSchoolYearNames = [];

        this.attendeeSections = [];

        var systemSettingsLoaded = false;
        var pendingRegisterLoadRequest = false;

        this.receptionRegisterStateService = registerStateService;
        this.registerStateTypeEnum = this.receptionRegisterStateService.getRegisterStateTypeEnum();

        var initLoadingState = function (viewAttendant) {
          viewAttendant.updateInProgress = true;
          viewAttendant.showError = false;
          viewAttendant.showWarning = false;
          viewAttendant.attendeeRegisterConfirmedState = null;
          viewAttendant.orginalRegisterState = viewAttendant.registerState;
        }.bind(this);

        var clearLoadingState = function (viewAttendant) {
          viewAttendant.showWarning = false;
          viewAttendant.hasAcceptedRegisterWillBeOverridden = false; //might change again and will be required to accept
          viewAttendant.updateInProgress = false;
          viewAttendant.attendeeRegisterConfirmedState = null;
        }.bind(this);

        this.arrangeAttendeeSections = function () {
          this.attendeeSections = this.eventRegisterTransportService.getAttendeeSections(this.selectedRegister);
        }.bind(this);

        this.updateRegisters = function (attendees, status) {
            let request = {
                registerState: status,
                hasAcceptedRegisterWillBeOverridden: attendees.every(a => a.hasAcceptedRegisterWillBeOverridden),
                attendanceNote: attendees.length === 1 ? attendees[0].attendanceNote : null,
                attendeeIds: attendees.map(a => a.calendarEventAttendeeId),
                // exitRegisterSetting is only available to save if there is only one attendee
                exitRegisterSetting: attendees.length === 1 ? {
                    setExitDTS: attendees[0].setExitDTS,
                    clearExitDTS: attendees[0].clearExitDTS,
                    exitCollectedByName: attendees[0].exitCollectedByName,
                    exitNote: attendees[0].exitNote
                } : undefined
            };
            attendees.forEach(initLoadingState);

            eventRegistersService.saveAttendeeRegisters(this.selectedRegister.eventId, request)
                .then(res => {
                    attendees.forEach(clearLoadingState);

                    if (!res.isSuccess) {
                        if (res.data?.isRegisterUnavailable) {
                            simpleAlertService.errorAlert({
                                message: 'SB_Events_Register_Is_Deleted',
                                okButtonText: 'SB_Refresh'
                            }).result.then(this.loadRegister.bind(this, false));
                        }

                        if (res.data?.requiresOverrideAcceptance){
                            res.data?.requiresOverrideAcceptanceRegisters?.forEach(register => {
                                let attendee = attendees.find(a => a.calendarEventAttendeeId === register.calendarEventAttendeeId);
                                initLoadingState(attendee);
                                attendee.showWarning = true;
                                attendee.attendeeRegisterConfirmedState = request.registerState;
                                attendee.orginalRegisterState = register.registerState;
                            });
                            return;
                        }
                    }

                    attendees.forEach(attendee => {
                        attendee.registerState = request.registerState;
                        attendee.registerStateLabel = this.getStateLabel(request.registerState);
                    });
                    this.arrangeAttendeeSections();
                    resetSubmitButtons();

                    this.selectedRegister.timeStamp = new Date();
                    this.selectedRegister.registerTaken = res.data.registerTaken;
                    if (typeof this.onRegisterUpdated === 'function') {
                        // notify other components that register state has updated.
                        this.onRegisterUpdated({ data: this.selectedRegister });
                    }
                })
                .catch(function (e) {
                    console.log(e);
                    attendees.forEach(attendee => {
                        attendee.showError = true;
                        //reset state to original
                        attendee.registerState = attendee.orginalRegisterState;
                        clearLoadingState(attendee);
                    });
                });
        };

        var resetSubmitButtons = function () {
          $timeout(
            function () {
              this.isSubmitted = false;
            }.bind(this),
            200
          );
        }.bind(this);

        //defaults
        this.selectedRegister = null;
        this.systemSettings = {};
        this.showConsentNotes = false;
        this.showAttendanceNotes = false;
        this.showDob = false;
        this.showClass = true;
        this.showYear = true;
        this.showAddTeacher = false;
        this.showAddAdmin = false;
        this.showAllRegisterOptions = true;
        this.showAddExternal = false;

        this.showNext = true;
        this.showPrevious = true;
        this.showDetailedView = true;
        this.sortByPreviousEventEndTime = false;
        this.sortByNextEventStartTime = false;

        this.isExitMode = false;

        this.colspan = 5;

        //Table Sorting
        this.sortType = 'name';
        this.sortReverse = false;

        //Button state
        this.isSubmitted = false;

        // save back the config settings to remember filter preferences
        this.saveUserSettings = function () {
          cachedLookupService.saveUserSettings({
            Reception_Register_ShowClass: this.showClass.toString().toLowerCase(),
            Reception_Register_ShowYear: this.showYear.toString().toLowerCase(),
            Reception_Register_ShowDOB: this.showDob.toString().toLowerCase(),
            Reception_Register_ShowAttendanceNotes: this.showAttendanceNotes.toString().toLowerCase(),
            Reception_Register_ShowConsentNotes: this.showConsentNotes.toString().toLowerCase(),
            Reception_Register_ShowDetailedView: this.showDetailedView.toString().toLowerCase(),
          });
        };

        this.reSetYearFilterToAll = function () {
          this.schoolYearNameFilter = [];
        };

        this.eventTimeOrderChanged = function () {
          switch (this.eventTimeOrder.value) {
            case 'P-ASC':
              this.sortType = 'previousEventEndTime';
              this.sortReverse = false;
              break;

            case 'N-ASC':
              this.sortType = 'nextEventStartTime';
              this.sortReverse = false;
              break;

            case 'P-DESC':
              this.sortType = 'previousEventEndTime';
              this.sortReverse = true;

            case 'N-DESC':
              this.sortType = 'nextEventStartTime';
              this.sortReverse = true;
              break;

            default:
              this.sortType = 'name';
              this.sortReverse = false;
          }
        };

        if (!systemSettingsLoaded) {
          cachedLookupService
            .getSystemSettings([
              'Reception_Register_ShowClass',
              'Reception_Register_ShowYear',
              'Reception_Register_ShowDOB',
              'Reception_Register_ShowAttendanceNotes',
              'Reception_Register_ShowConsentNotes',
              'Reception_Register_HasAdd_Teacher',
              'Reception_Register_HasAdd_Admin',
              'Reception_Register_HasAdd_External',
              'Reception_Register_ShowDetailedView',
            ])
            .then(
              function (settings) {
                this.systemSettings = settings;

                this.showConsentNotes = settings['Reception_Register_ShowConsentNotes'].toString().toLowerCase() === 'true';
                this.showAttendanceNotes = settings['Reception_Register_ShowAttendanceNotes'].toString().toLowerCase() === 'true';
                this.showDob = settings['Reception_Register_ShowDOB'].toString().toLowerCase() === 'true';
                this.showClass = settings['Reception_Register_ShowClass'].toString().toLowerCase() === 'true';
                this.showYear = settings['Reception_Register_ShowYear'].toString().toLowerCase() === 'true';
                this.showAddTeacher = settings['Reception_Register_HasAdd_Teacher'].toString().toLowerCase() === 'true';
                this.showAddAdmin = settings['Reception_Register_HasAdd_Admin'].toString().toLowerCase() === 'true';
                this.showAddExternal = settings['Reception_Register_HasAdd_External'].toString().toLowerCase() === 'true';
                this.showDetailedView = settings['Reception_Register_ShowDetailedView'].toString().toLowerCase() === 'true';

                if (this.showDob) {
                  this.colspan++;
                }

                if (this.showYear) {
                  this.colspan++;
                }

                if (this.showClass) {
                  this.colspan++;
                }

                if (this.showAbsentColumn) {
                  this.colspan++;
                }

                systemSettingsLoaded = true;

                if (pendingRegisterLoadRequest) {
                  pendingRegisterLoadRequest = false;
                  this.loadRegister();
                }
              }.bind(this)
            );
        }

        this.$onChanges = function (changes) {
          if ((changes.eventId && changes.eventId.currentValue) || (changes.lastRefreshed && changes.lastRefreshed.currentValue)) {
            this.loadRegister(changes.lastRefreshed !== undefined);
          }
        }.bind(this);

        this.$onDestroy = function () {
          try {
            delete $window.onbeforeunload;
          } catch (err) {}
        };

        this.anyAttendeeHasWarning = function () {
          if (!this.selectedRegister || !this.selectedRegister.attendants) {
            return false;
          }
          //any attendants with warnings?
          if (
            this.selectedRegister.attendants.filter(function (e) {
              return e.showWarning === true;
            }).length > 0
          ) {
            return true;
          }
          return false;
        };

        this.toggleDetailedView = function () {
          this.showNext = this.showDetailedView;
          this.showPrevious = this.showDetailedView;
        };

        this.compareSchoolYearNames = function (a, b) {
          // Use toUpperCase() to ignore character casing
          var schoolIdA = a.id.toUpperCase();
          var schoolIdB = b.id.toUpperCase();

          var comparison = 0;

          if (schoolIdA > schoolIdB) {
            comparison = 1;
          } else if (schoolIdA < schoolIdB) {
            comparison = -1;
          }

          return comparison;
        };

        this.loadRegister = function (noSpinner) {
          if (!systemSettingsLoaded) {
            pendingRegisterLoadRequest = true;
            return;
          }

          if (!noSpinner) {
            delete this.selectedRegister;
          }

          eventRegistersService.getEventRegister(this.eventId).then(
            function (data) {
              if (!data) {
                this.loadFailureMessage = 'SB_Error_loading';
                return;
              }

              this.uniqueSchoolYearNames = [];
              this.selectedRegister = data;

              if (typeof this.onRegisterUpdated === 'function') {
                // notify other components that register state has updated.
                this.onRegisterUpdated({ data: data });
              }

              if (this.isTransportEvent()) {
                // only attempt to load location history for transport events
                this.loadRegisterLocations();
              }

              this.selectedRegister.attendants.forEach((attendant) => {
                attendant.registerStateLabel = this.getStateLabel(attendant.registerState);
              });

              const isAddVisibleForCurrentUser = Boolean(
                (this.isExternal && this.showAddExternal) || (this.isOrganisationAdmin && this.showAddAdmin) || (this.isTeacher && this.showAddTeacher)
              );
              // add to register visible to anyone for transport events and based on some roles/settings for non transport
              // events for events that have started
              this.canAdd = data.hasAddStudentButton && data.hasStarted && (this.isTransportEvent() || isAddVisibleForCurrentUser);

              // ensure stops are in calling point order.
              if (this.selectedRegister.transportRouteStops) {
                this.selectedRegister.transportRouteStops = $filter('orderBy')(this.selectedRegister.transportRouteStops, 'stopOrder');

                this.isSchoolboundTransportRegister = this.selectedRegister.isSchoolboundTransportRegister;
              }

              if (this.selectedRegister.includesExitData) {
                this.colspan++;
              }

              var uniqueSchoolYearNameItems = $filter('unique')(this.selectedRegister.attendants, 'schoolYearName');

              for (var i = 0; i < uniqueSchoolYearNameItems.length; i++) {
                if (uniqueSchoolYearNameItems[i].schoolYearName && uniqueSchoolYearNameItems[i].schoolYearName.length > 0) {
                  this.uniqueSchoolYearNames.push({
                    label: uniqueSchoolYearNameItems[i].schoolYearName,
                    id: uniqueSchoolYearNameItems[i].schoolYearName,
                  });
                }
              }

              if (typeof this.selectedRegister.attendants !== 'undefined' && this.selectedRegister.attendants.length > 0) {
                blobStoragePhotosService.addStudentPhotoUrlsToArray(this.selectedRegister.attendants).then(
                  function (array) {
                    this.selectedRegister.attendants = array;
                  }.bind(this)
                );
              }

              this.arrangeAttendeeSections();

              this.uniqueSchoolYearNames.sort(this.compareSchoolYearNames);
              this.schoolYearNameFilter = this.uniqueSchoolYearNames[0];

              this.showPreviousEvent();
              this.showNextEvent();

              this.resetActionedId();

              if (this.isModalOnly && this.canAdd) {
                this.addMultiplePupils();
              }
            }.bind(this),
            function (error) {
              this.loadFailureMessage = 'SB_Error_loading';
            }.bind(this)
          );
        }.bind(this);

        this.loadRegisterLocations = function () {
            eventRegistersService.getRegisterLocations(this.eventId).then(
                function (response) {
                    this.selectedRegister.locationLogs = response.data;
                }.bind(this)
            );
        }

        this.showNextEvent = function () {
          eventRegistersService
            .getRegisterNextEvent(this.eventId) //(this.selectedEvent.calendarEventId)
            .then(
              function (data) {
                this.nextData = data;
                for (var i = 0; i < this.selectedRegister.attendants.length; ++i) {
                  var attendant = this.selectedRegister.attendants[i];
                  for (var j = 0; j < data.length; ++j) {
                    var eventItem = data[j];

                    if (eventItem.personId === attendant.personId) {
                      attendant.nextEvent = eventItem;
                      attendant.nextEventStartTime = attendant.nextEvent.eventStartTime;
                      break;
                    }
                  }
                }
              }.bind(this),
              function (error) {
                return error;
              }.bind(this)
            );
        };

        this.showPreviousEvent = function () {
          eventRegistersService
            .getRegisterPreviousEvent(this.eventId) //(this.selectedEvent.calendarEventId)
            .then(
              function (data) {
                this.previousData = data;
                for (var i = 0; i < this.selectedRegister.attendants.length; ++i) {
                  var attendant = this.selectedRegister.attendants[i];
                  for (var j = 0; j < data.length; ++j) {
                    var eventItem = data[j];

                    if (eventItem.personId === attendant.personId) {
                      attendant.previousEvent = eventItem;
                      attendant.previousEventEndTime = attendant.previousEvent.eventEndTime;
                      break;
                    }
                  }
                }
              }.bind(this),
              function (error) {
                return error;
              }.bind(this)
            );
        };

        this.registerAllHere = function (state) {
          if (this.showAllRegisterOptions === false) {
            return;
          } //exit already clicked

          this.showAllRegisterOptions = false;

          var attendeesToRegister = this.attendeesToRegister();

          if (attendeesToRegister.length == 0) {
            this.showAllRegisterOptions = true;
            resetSubmitButtons();
            return;
          }

          this.updateRegisters(attendeesToRegister, state);

          this.showAllRegisterOptions = true;
        }.bind(this);

        this.notifyParentsOfAbsentees = function () {
          var modalInstance = simpleAlertService.simpleAlert({
            title: 'SB_Notifications',
            message: 'SB_This_will_send_an_email_to_the_parent__guardians_of_absentees_only',
            okButtonText: 'SB_OK',
            cancelButtonText: 'SB_Cancel',
          });

          var successCallback = function () {
            resetSubmitButtons();
          };

          var failureCallback = function () {
            //failure
            simpleAlertService.errorAlert();

            resetSubmitButtons();
          };

          modalInstance.result.then(
            function () {
              this.selectedRegister.sendParentAbsenteeNotification = true;
              eventRegistersService.notifyParentsOfAbsentees(this.selectedRegister, successCallback, failureCallback);
            }.bind(this),
            function () {
              resetSubmitButtons();
            }
          );
        }.bind(this);

        this.updateSingleAttendant = function (viewAttendant, status, nextPersonId) {
          if (this.isReadOnly) {
            return;
          }

          //On-click is triggering this and checking the check box.
          //AngularJS is controlling the checked property however stays checked even when the property is removed. (https://github.com/angular/angular/issues/9275)
          event.preventDefault();

          this.currentAttendee = viewAttendant.id;

          //un-checking selection.
          if (status === viewAttendant.registerState) {
            status = 0;
          } else {
            this.setActionedId(nextPersonId);
          }

          this.updateRegisters([viewAttendant], status);
        }.bind(this);

        this.clearExitState = function (attendee) {
          attendee.setExitDTS = false;
          attendee.exitDTS = null;
          //attendee.exitNote = ''; // deliberatly excluded as requested in SB-259
          attendee.exitRecordedByPersonId = null;
          attendee.exitCollectedByName = null;
          attendee.exitLatitude = null;
          attendee.exitLongitude = null;
        };

        this.additionalDetailsPopup = function (attendee, register) {
          this.setMapMarkers(attendee, register);
          var modalInstance = $uibModal.open({
            animation: this.animationsEnabled,
            templateUrl: 'exit-register-confirmation.html',
            controller: 'exitRegisterConfirmationController',
            size: 'md',
            backdrop: 'static',
            resolve: {
              attendee: function () {
                return attendee;
              }.bind(this),
              updateRegisters: function () {
                return this.updateRegisters.bind(this);
              }.bind(this),
              register: function () {
                return register;
              }.bind(this),
              initLoadingState: function () {
                return initLoadingState;
              }.bind(this),
            },
          });

          modalInstance.result
            .then(function () {
              resetSubmitButtons();
            })
            .catch(function () {
              resetSubmitButtons();
            });
        }.bind(this);

        this.resolveConflicts = function (attendee) {
          var modalInstance = $uibModal.open({
            animation: this.animationsEnabled,
            templateUrl: 'conflicts-register-confirmation.html',
            controller: 'conflictRegisterConfirmationController',
            size: 'md',
            backdrop: 'static',
            resolve: {
              attendee: function () {
                return attendee;
              },
              updateRegisters: function () {
                return this.updateRegisters.bind(this);
              }.bind(this),
            },
          });

          modalInstance.result
            .then(function () {
              resetSubmitButtons();
            })
            .catch(function () {
              resetSubmitButtons();
            });
        };

        this.attendanceNotePopup = function (attendee, register) {
          this.setMapMarkers(attendee, register);
          var modalInstance = $uibModal.open({
            animation: this.animationsEnabled,
            templateUrl: 'attendance-note.html',
            controller: 'attendanceNoteController',
            size: 'md',
            backdrop: 'static',
            resolve: {
              attendee: function () {
                return attendee;
              }.bind(this),
              updateRegisters: function () {
                return this.updateRegisters.bind(this);
              }.bind(this),
              register: function () {
                return register;
              }.bind(this),
              initLoadingState: function () {
                return initLoadingState;
              }.bind(this),
            },
          });

          modalInstance.result
            .then(function () {
              resetSubmitButtons();
            })
            .catch(function () {
              resetSubmitButtons();
            });
        }.bind(this);

        this.locationLogPopup = function (attendee, register) {
            this.setMapMarkers(attendee, register);

            var modalInstance = $uibModal.open({
              animation: this.animationsEnabled,
              templateUrl: 'location-logs.html',
              controller: 'locationLogsController',
              size: 'md',
              backdrop: 'static',
              resolve: {
                attendee: function () {
                  return attendee;
                }.bind(this),
                register: function () {
                  return register;
                }.bind(this),
                initLoadingState: function () {
                  return initLoadingState;
                }.bind(this),
              },
            });

            modalInstance.result
              .then(function () {
                resetSubmitButtons();
              })
              .catch(function () {
                resetSubmitButtons();
              });
          }.bind(this);

          this.updateAttendanceAllPopup = function (status) {
          var attendeesToRegister = this.attendeesToRegister();
          var modalInstance = $uibModal.open({
            animation: this.animationsEnabled,
            templateUrl: 'update-attendance-all.html',
            controller: 'updateAttendanceAllController',
            size: 'md',
            backdrop: 'static',
            resolve: {
              status: function () {
                return status;
              },
              attendeesToRegister: function () {
                return attendeesToRegister;
              },
            },
          });

          modalInstance.result
            .then(
              function () {
                this.registerAllHere(status, attendeesToRegister);
                resetSubmitButtons();
              }.bind(this)
            )
            .catch(function () {
              resetSubmitButtons();
            });
        }.bind(this);

        this.moveToExpectedPopup = function (item) {
          var modalInstance = $uibModal.open({
            animation: this.animationsEnabled,
            templateUrl: 'move-to-expected.html',
            controller: 'moveToExpectedController',
            size: 'md',
            backdrop: 'static',
          });

          modalInstance.result
            .then(
              function () {
                this.moveToExpected(item);
                resetSubmitButtons();
              }.bind(this)
            )
            .catch(
              function () {
                resetSubmitButtons();
              }.bind(this)
            );
        }.bind(this);

        this.toggleExit = function (attendee, register) {
          if (this.isReadOnly) {
            return;
          }
          if (!attendee.setExitDTS && !attendee.exitDTS) {
            attendee.setExitDTS = true;
          } else {
            this.clearExitState(attendee);
            attendee.clearExitDTS = true;
          }

          initLoadingState(attendee);

          this.updateRegisters([attendee], attendee.registerState);
        }.bind(this);

        this.notifyStaffOfAbsentees = function () {
          var successCallbackEvent = function () {
            resetSubmitButtons();
          };

          var failureCallbackEvent = function () {
            simpleAlertService.errorAlert();

            resetSubmitButtons();
          };

          eventRegistersService.sendNotifyStaffOfAbsentees(this.selectedRegister, successCallbackEvent, failureCallbackEvent);
        }.bind(this);

        this.addMultiplePupils = function () {
          var selectedIds = [];

          angular.forEach(this.selectedRegister.attendants, function (item) {
            selectedIds.push(item.personId);
          });

          var modalInstance = personPickerService.openMultiPersonPicker({
            selectedPersonIds: selectedIds,
            defaultClubId: 0,
            defaultTeamId: 0,
            okButtonText: this.isRouteTransportEvent() ? 'SB_Next' : 'SB_Save',
            includeStaff: false,
            includePupils: true,
            hideGroupFilter: false,
            hideYearFilter: false,
            hideGenderFilter: false,
            hideRemove: true,
            filterOutExternalStaff: false,
            shownFromRegister: true,
            shownFromStaffRoles: false,
          });

          modalInstance.result
            .then(this.setAttendeeStops)
            .then(this.postPersonChangesToEvent)
            .catch(
              function () {
                // dialog cancelled
                this.loadRegister();

                resetSubmitButtons();
              }.bind(this)
            );
        }.bind(this);

        this.setAttendeeStops = function (selectedIds) {
          if (!this.isRouteTransportEvent()) {
            return selectedIds.map(function (id) {
              return {
                personId: id,
              };
            });
          }

          var addedIds = selectedIds.filter(
            function (id) {
              return !this.selectedRegister.attendants.find(function (attendant) {
                return attendant.personId == id;
              });
            }.bind(this)
          );

          if (addedIds.length == 0) {
            // no-one was added
            return;
          }

          return personService.getNames(addedIds).then(
            function (data) {
              var students = data.map(function (student) {
                return {
                  id: student.id,
                  ln: student.lastName,
                  fn: student.firstName,
                  on: student.otherName,
                };
              });

              var modalInstance = $uibModal.open({
                component: 'eventsRegisterTransportAddStudentSelectStops',
                resolve: {
                  students: function () {
                    return students;
                  },
                  routeStops: function () {
                    return this.selectedRegister.transportRouteStops;
                  }.bind(this),
                },
                size: 'lg',
              });

              return modalInstance.result.then(function (students) {
                return students.map(function (student) {
                  return {
                    personId: student.id,
                    routeStopId: student.routeStopId,
                  };
                });
              });
            }.bind(this),
            function (error) {
              console.log(error);
              simpleAlertService.errorAlert();
            }
          );
        }.bind(this);

        this.postPersonChangesToEvent = function (personStops) {
          if (!personStops) {
            // no-one was added
            resetSubmitButtons();
            return;
          }

          var pleaseWaitModalInstance = simpleAlertService.pleaseWaitModal({});

          resetSubmitButtons();

          return eventsService
            .postPersonChangesToEvent(+this.eventId, this.selectedRegister.transportEventId, personStops, $window.EveryBuddy.Enums.RoleTypes.Pupil)
            .then(
              function (data) {
                pleaseWaitModalInstance.close();

                if (!data) {
                  console.error('Problem saving team changes');
                  simpleAlertService.errorAlert();
                  throw 'postPersonChangesToEvent return false';
                }

                if (this.isModalOnly) {
                  // Redirect for webview to catch as process completed
                  $window.location.href = '/';
                }

                // reload team data
                return eventRegistersService.getEventRegister(this.eventId).then(
                  function (data) {
                    this.selectedRegister.attendants = data.attendants;

                    this.arrangeAttendeeSections();

                    if (typeof this.onAttendeesAddedToEvent === 'function') {
                      this.onAttendeesAddedToEvent({ data: data.attendants });
                      blobStoragePhotosService.addStudentPhotoUrlsToArray(this.selectedRegister.attendants).then(
                        function (array) {
                          this.selectedRegister.attendants = array;
                          this.showPreviousEvent();
                          this.showNextEvent();
                        }.bind(this)
                      );
                    }
                  }.bind(this),
                  function (error) {
                    console.log(error);
                    simpleAlertService.errorAlert();
                  }
                );
              }.bind(this),
              function (error) {
                console.log(error);
                simpleAlertService.errorAlert();
              }
            );
        }.bind(this);

        this.search = function (item) {
          if (!this.filter.text && this.filter.selectedSchoolYearNames.length == 0 && !this.routeStopId) {
            return true;
          }

          var nameMatch = this.filter.text == '';

          var searchTextArray = this.filter.text.toLowerCase().split(' ');

          angular.forEach(searchTextArray, function (textItem) {
            if (item.name.toLowerCase().indexOf(textItem) !== -1) {
              nameMatch = true;
            }
          });

          var yearMatch = this.filter.selectedSchoolYearNames.length == 0;

          var selectedYearNames = this.filter.selectedSchoolYearNames;
          var selectedYearIds = Object.keys(selectedYearNames).map(function (e) {
            return selectedYearNames[e].id;
          });

          if (selectedYearIds.includes(item.schoolYearName)) {
            yearMatch = true;
          }

          const stopMatch = this.matchStop()(item);

          return nameMatch && yearMatch && stopMatch;
        }.bind(this);

        this.orderByRelevance = function () {
          var filteredAttendants = $filter('filter')(this.selectedRegister.attendants, this.search);
          filteredAttendants.forEach(
            function (att) {
              att.searchScore = searchService.getPupilRelevance(att, this.filter.text);
            }.bind(this)
          );
          this.sortType = 'searchScore';
          this.sortReverse = true;
        }.bind(this);

        this.toggleExpandSection = function (section) {
          section.config.expanded = !section.config.expanded;
        };

        this.scrollToLastAttendee = function (tabIndex) {
          var tabPane = $angular.element('.tabs-scrollable .tab-pane')[tabIndex];
        };

        this.matchState = function (state) {
          return (item) => {
            return item.registerState == state;
          };
        };

        this.matchStop = () => {
          return (item) => {
            return !this.routeStopId || item.transportAttendeeRouteStopId === this.routeStopId;
          };
        };

        this.matchStateAndStop = (state) => {
          return (item) => {
            return this.matchState(state)(item) && this.matchStop()(item);
          };
        };

        this.getFilteredAttendeeCount = (section) => {
          return section.attendees.filter((attendee) => {
            return this.matchStop()(attendee);
          }).length;
        };

        this.attendeesToRegister = function () {
          return this.selectedRegister.attendants.filter(function (att) {
            return !att.registerState && att.registerCanBeTaken;
          });
        }.bind(this);

        this.setActionedId = function (personId) {
          if (this.isReadOnly) {
            return;
          }
          if (this.registerActionedId == personId) {
            this.registerActionedId = 0;
          } else {
            this.registerActionedId = personId;
          }
        }.bind(this);

        this.resetActionedId = function () {
          if (this.selectedRegister.attendants.length > 0) {
            if (
              !this.attendeeSections ||
              !this.attendeeSections.length > -1 ||
              !this.attendeeSection[0].subsections ||
              !this.attendeeSections[0].subsections.length > -1 ||
              !this.attendeeSections[0].subsections[0].attendees ||
              !this.attendeeSections[0].subsections[0].attendees.length > -1
            ) {
              return;
            }

            var firstPerson = this.attendeeSections[0].subsections[0].attendees[0].personId;
            if (firstPerson) {
              this.setActionedId(firstPerson);
            }
          }
        };

        this.showTable = function () {
          // Exit mode: check for present or late attendees
          // Other modes: check for attendees
          var filteredAttendants = this.selectedRegister.attendants.filter(
            function (item) {
              return this.search(item);
            }.bind(this)
          );
          var presentAttendants = filteredAttendants.filter(
            function (item) {
              return this.matchState(1)(item) || this.matchState(3)(item);
            }.bind(this)
          );
          return (this.mode !== 'e' && filteredAttendants.length > 0) || presentAttendants.length > 0;
        }.bind(this);

        this.createTransportEventLogEntry = (transportRouteStopId) => {
          let calendarEventTransportLogRequest = {
            calendarEventTransportId: this.selectedRegister.transportEventId,
            transportTimetableRouteId: this.selectedRegister.transportTimetableRouteId,
            transportRouteStopId: transportRouteStopId,
          };

          eventRegistersService
            .createCalendarEventTransportLogEntry(calendarEventTransportLogRequest)
            .then((result) => {
              this.resetSubmitButtons();
            })
            .catch(function (error) {
              resetSubmitButtons();
            });
        };

        //Register states

        this.registersStates = [
          this.registerStateTypeEnum.Here,
          this.registerStateTypeEnum.NotHere,
          this.registerStateTypeEnum.Excused,
          this.registerStateTypeEnum.Late,
        ];

        this.getStateLabel = function (registerState) {
          return this.receptionRegisterStateService.getRegisterStateBadgeText(registerState);
        };

        this.getStateColorClass = function (registerState) {
          return this.receptionRegisterStateService.getRegisterStateColorClass(registerState);
        };

        this.getStateIconName = function (registerState) {
          return this.receptionRegisterStateService.getRegisterStateIconName(registerState);
        };

        this.getStateLabelIconName = function (registerState) {
          return this.receptionRegisterStateService.getRegisterStateLabelIconName(registerState);
        };

        this.fileTypeEnum = fileService.getFileTypeEnum();

        //Download PDF or CSV
        this.download = function () {
          // Create a list of attendeeSections based on filtered attendees
          var filteredAttendants = this.selectedRegister.attendants.filter(
            function (att) {
              return this.search(att);
            }.bind(this)
          );
          var filteredRegister = Object.assign({}, this.selectedRegister);
          filteredRegister.attendants = [].concat(filteredAttendants);
          var filteredAttendeeSections = this.eventRegisterTransportService.getAttendeeSections(filteredRegister);
          filteredRegister.attendeeSections = filteredAttendeeSections;
          registerFileService.formatRegisters([filteredRegister]);
          resetSubmitButtons();
        }.bind(this);

        this.setMapMarkers = function(attendee, register) {
          if (!register || !register.locationLogs) {
            return;
          }

          const locationLogs = register.locationLogs.filter( log => log.calendarEventAttendeeId === attendee.calendarEventAttendeeId);
          if (locationLogs.length > 0) {
            var markers = [];
            locationLogs.forEach( (log) => {
                markers.push({
                    lat: log.latitude,
                    lng: log.longitude,
                    tooltip: log.timestamp,
                    colour: $('.text-' + (log.activity === 1 ? 'danger' : 'success') + '-light').css('color'),
                    title: '<i class="fa-solid fa-arrow-right-' + (log.activity === 1 ? 'from' : 'to') + '-bracket"></i>'
                });
            });
            attendee.locationLogMarkers = markers;
          }
        }

        this.locationLogExists = function(attendee) {
            return this.selectedRegister.locationLogs?.some( log => log.calendarEventAttendeeId === attendee.calendarEventAttendeeId);
        }
      },
    ],
  })
  .controller('exitRegisterConfirmationController', [
    '$scope',
    '$uibModalInstance',
    'attendee',
    'updateRegisters',
    'initLoadingState',
    function ($scope, $uibModalInstance, attendee, updateRegisters, initLoadingState) {
      var existingAttendeeExitNote = attendee.exitNote;
      var existingAttendeeExitCollectedByName = attendee.exitCollectedByName;

      $scope.attendee = attendee;

      $scope.save = function () {
        $scope.attendee.setExitDTS = true;

        initLoadingState(attendee);

        updateRegisters([attendee], attendee.registerState);

        $uibModalInstance.close($scope.attendee);
      };

      $scope.cancel = function () {
        $scope.attendee.updateInProgress = false;

        $scope.attendee.exitNote = existingAttendeeExitNote;

        $scope.attendee.exitCollectedByName = existingAttendeeExitCollectedByName;

        $uibModalInstance.dismiss('cancel');
      };
    },
  ])
  .controller('locationLogsController', [
    '$scope',
    '$uibModalInstance',
    'attendee',
    function ($scope, $uibModalInstance, attendee) {
      $scope.attendee = attendee;

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ])
  .controller('attendanceNoteController', [
    '$scope',
    '$uibModalInstance',
    'attendee',
    'updateRegisters',
    'initLoadingState',
    function ($scope, $uibModalInstance, attendee, updateRegisters, initLoadingState) {
      var existingAttendeeAttandanceNote = attendee.attendanceNote;

      $scope.attendee = attendee;

      $scope.save = function () {
        initLoadingState(attendee);

        updateRegisters([attendee], attendee.registerState);

        $uibModalInstance.close($scope.attendee);
      };

      $scope.cancel = function () {
        attendee.updateInProgress = false;

        $scope.attendee.attendanceNote = existingAttendeeAttandanceNote;

        $uibModalInstance.dismiss('cancel');
      };
    },
  ])
  .controller('updateAttendanceAllController', [
    '$scope',
    '$uibModalInstance',
    'registerStateService',
    'status',
    'attendeesToRegister',
    function ($scope, $uibModalInstance, registerStateService, status, attendeesToRegister) {
      $scope.status = status;
      $scope.service = registerStateService;
      $scope.statusLabel = $scope.service.getRegisterStateBadgeText($scope.status);
      $scope.attendeesToRegister = attendeesToRegister;

      $scope.save = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ])
  .controller('moveToExpectedController', [
    '$scope',
    '$uibModalInstance',
    function ($scope, $uibModalInstance) {
      $scope.move = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ])
  .controller('conflictRegisterConfirmationController', [
    '$scope',
    '$uibModalInstance',
    '$filter',
    'registerStateService',
    'attendee',
    'updateRegisters',
    function ($scope, $uibModalInstance, $filter, registerStateService, attendee, updateRegisters) {
      var returnTranslationValue = function (status) {
        var service = registerStateService;
        return service.getRegisterStateBadgeText(status) || 'SB_No_Register_Taken';
      };

      $scope.attendee = attendee;

      $scope.translateState = function (status) {
        return $filter('translate')(returnTranslationValue(status));
      };

      $scope.save = function () {
        attendee.registerState = attendee.attendeeRegisterConfirmedState;

        attendee.hasAcceptedRegisterWillBeOverridden = true;

        attendee.showWarning = false;

        updateRegisters([attendee], attendee.registerState);

        $uibModalInstance.close(attendee);
      };

      $scope.cancel = function () {
        attendee.registerState = attendee.orginalRegisterState;

        attendee.showWarning = false;

        attendee.hasAcceptedRegisterWillBeOverridden = false;

        attendee.updateInProgress = false;

        $uibModalInstance.close(attendee);
      };
    },
  ]);
