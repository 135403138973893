'use strict';

angular.module('ccaavailableModule').component("ccaAvailableDetails", {
    templateUrl: "/Scripts/app/ccaAvailable/Details/cca-available-details.template.html",
    controller: [
        "$scope",
        "$state",
        "availableRepository",
        'simpleAlertService',
        'blobStoragePhotosService',
        function (
            $scope,
            $state,
            availableRepository,
            simpleAlertService,
            blobStoragePhotosService) {

            $scope.isCollapsed = false;
            $scope.saving = false;
            $scope.isClosed = false;

            $scope.signUp = {};

            // if ($window.mobileMode())
            // {
            //     $scope.isCollapsed = true;
            // }

            availableRepository.getSignUp($state.params.signUpId)
                .then(function (signUp) {
                    $scope.signUp = signUp;

                    $scope.isClosed = moment(signUp.closeDate).isBefore();
                });

            availableRepository.getSignUpGroups($state.params.signUpId)
                .then(function (signUpGroups) {
                    $scope.groups = signUpGroups;
                    blobStoragePhotosService.addStudentPhotoUrlsToNestedArrays($scope.groups).then(function (array) {
                        $scope.groups = array;
                    });
                });
            $scope.review = function () {
                $scope.saving = true;
                angular.forEach($scope.groups,
                    function(group, index)
                    {
                        group.title = "";
                        group.description = "";
                    });
                availableRepository.post($scope.groups, $state.params.signUpId)
                    .then(function () {
                        $state.go("ccaReview", { signUpReviewId: $scope.signUp.ccaSignUpId });
                    });
            };
            $scope.update = function () {
                // Will this save photos??
                $scope.saving = true;
                angular.forEach($scope.groups,
                    function(group, index)
                    {
                        group.title = "";
                        group.description = "";
                    });
                availableRepository.post($scope.groups, $state.params.signUpId)
                    .then(function(signUpGroups)
                    {
                        $scope.groups = signUpGroups;
                        $scope.saving = false;
                        simpleAlertService.simpleAlert({
                            title: 'SB_Saved',
                            message: 'SB_Save_Complete',
                            windowSize: 'sm'
                        });
                    }).catch(function (err) {
                        $scope.saving = false;
                        console.error(err);
                        simpleAlertService.errorAlert({ message: 'SB_Error_Saving_Message'});
                    });
            };
        }
    ]});