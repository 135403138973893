'use strict';

angular.module('transport.configuration.timetableCalendar.transportTimetableCalendarEdit',
    [
        'transport.services.transportTimetableCalendarsService',
        'shared.components.sbOnOffSwitch',
        'shared.services.iconService',
        'shared.services.simpleAlertService',
        'shared.components.sbDateRangePicker',
        'shared.constants'
    ])
    .component('transportTimetableCalendarEdit',
        {
            bindings: {
                resolve: '<',
                modalInstance: '<',
            },
            templateUrl: '/Scripts/app/transport/configuration/timetableCalendar/transport-timetable-calendar-edit.template.html',
            controller: [
                '$timeout',
                '$uibModal',
                'cachedLookupService',
                'dateRangeLists',
                'transportTimetableCalendarsService',
                'simpleAlertService',
                function transportTimetableCalendarEditController($timeout,
                    $uibModal,
                    cachedLookupService,
                    dateRangeLists,
                    transportTimetableCalendarsService,
                    simpleAlertService)
                {
                    this.isSubmitted = false;
                    this.anyChanges = false;
                    this.addingNewDateRange = false;
                    this.editingDateRange = false;
                    this.dateRangeUnderEditId = 0;
                    this.dateRangeLists = dateRangeLists;
                    this.fromDate = moment().startOf('day');
                    this.toDate = moment().endOf('day');
                    this.today = moment().startOf('day');
                    this.newDateRangeId = -1;
                    this.transportTimetableCalendarArchivingInfo = {};
                    this.isArchiveOptionAvailable = false;
                    this.todayPlus20Days = moment().add(20, 'd').toDate();
                    this.anyInvalidDateRanges = false;
                    this.anyOverlapsExist = false;
                    this.allDateRangesInPast = false;

                    var resetSubmitButtons = function ()
                    {
                        $timeout(function ()
                        {
                            this.isSubmitted = false;
                        }.bind(this), 200);

                    }.bind(this);

                    this.$onChanges = function (changes)
                    {
                        if (changes.resolve)
                        {
                            var params = this.resolve.params;

                            this.transportTimetableCalendarResponse = params.responseDto;
                            this.timetableCalendarId = this.transportTimetableCalendarResponse.id;
                            transportTimetableCalendarsService.getTransportTimetableCalendar(this.timetableCalendarId)
                                .then((transportTimetableCalendar) => {
                                    this.transportTimetableCalendar =
                                    {
                                        id: this.timetableCalendarId,
                                        name: transportTimetableCalendar.name,
                                        dateRanges: transportTimetableCalendar.dateRanges,
                                        isDefault: transportTimetableCalendar.isDefault
                                    };
                                    this.allDateRangesInPast = !transportTimetableCalendar.dateRanges?.some(dr => !dr.isDeleted && moment(dr.toDate).isAfter(moment().startOf('day')));

                                    this.transportTimetableCalendar.dateRanges.forEach(function(dateRange)
                                    {
                                        dateRange.overlapError = false;
                                    });
                                });

                            transportTimetableCalendarsService.getTransportTimetableCalendarArchivingInfo(this.timetableCalendarId)
                                .then(function (response)
                                {
                                    this.transportTimetableCalendarArchivingInfo = response;

                                    cachedLookupService.isOrganisationSuperAdminOrTransportAdvancedAdmin()
                                        .then(function (response)
                                        {
                                            this.isArchiveOptionAvailable = response == 1;
                                        }.bind(this))
                                        .catch(function (responseData)
                                        {
                                            this.handleException(responseData);
                                        }.bind(this));

                                }.bind(this))
                                .catch(function (responseData)
                                {
                                    this.handleException(responseData);
                                }.bind(this));
                        }
                    }.bind(this);

                    this.cancel = function ()
                    {
                        this.modalInstance.dismiss('cancel');
                    };

                    this.handleException = function (responseData)
                    {
                        var message = {};

                        if (responseData && responseData.data && responseData.data.Message)
                        {
                            message = { message: responseData.data.Message };
                        }

                        alertAndResetOkButton(message);
                    }

                    this.formatDate = function (date, endOfDay)
                    {
                        return endOfDay ? date.format('YYYY-MM-DDT23:59:59') : date.format('YYYY-MM-DD');
                    }

                    this.setAsDefaultTooltipText = () => {
                        return this.allDateRangesInPast ?
                            'SB_TransportTimetableCalendar_IsDefault_Tooltip_DateInPast_Warning' :
                            'SB_TransportTimetableCalendar_IsDefault_Tooltip';
                    };

                    this.editTransportTimetableCalendar = function ()
                    {
                        if (this.transportTimetableCalendar.name &&
                            this.transportTimetableCalendar.dateRanges.length > 0)
                        {
                            var editedTransportTimetableCalendar =
                            {
                                id: this.timetableCalendarId,
                                name: this.transportTimetableCalendar.name,
                                dateRanges: [],
                                isDefault: this.transportTimetableCalendar.isDefault,
                            };

                            for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                            {
                                var dateRange = Object.assign({}, this.transportTimetableCalendar.dateRanges[i]);

                                editedTransportTimetableCalendar.dateRanges.push(dateRange);
                            }

                            transportTimetableCalendarsService.editTransportTimetableCalendar(editedTransportTimetableCalendar)
                                .then(function (responseDto)
                                {
                                    if (responseDto && responseDto.name)
                                    {
                                        simpleAlertService.simpleAlert({
                                            title: 'SB_Saved',
                                            message: 'SB_Transport_Timetable_Calendar_Saved',
                                            messageTranslationParameters: { name: responseDto.name },
                                            staticBackdrop: true
                                        });

                                        this.modalInstance.close();
                                    }
                                    else
                                    {
                                        alertAndResetOkButton();
                                    }
                                }.bind(this))
                                .catch(function (responseData)
                                {
                                    this.handleException(responseData);
                                }.bind(this));
                        }
                    }.bind(this);

                    this.archiveTransportTimetableCalendar = function ()
                    {
                        var transportItemNameToArchive = this.transportTimetableCalendar.name;

                        var confirmArchiveModalInstance = $uibModal.open(
                            {
                                animation: true,
                                component: 'transportConfirmArchiving',
                                size: 'md',
                                backdrop: 'static',
                                resolve:
                                {
                                    params: function ()
                                    {
                                        return { transportItemNameToArchive: transportItemNameToArchive };
                                    }
                                }
                            });

                        confirmArchiveModalInstance.result
                            .then(
                                () => {
                                    transportTimetableCalendarsService.archiveTimetableCalendar(this.transportTimetableCalendar)
                                        .then(function (responseData)
                                        {
                                            if (responseData && responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Transport_Timetable_Calendar_Archived',
                                                    message: 'SB_Transport_Timetable_Calendar_Archived',
                                                    staticBackdrop: true
                                                });

                                                resetSubmitButtons();
                                                this.modalInstance.close();
                                            }
                                            else if (responseData && !responseData.isArchiveSuccess)
                                            {
                                                simpleAlertService.simpleAlert({
                                                    title: 'SB_Error',
                                                    message: 'SB_Transport_Archive_Operation_Failed',
                                                    staticBackdrop: true
                                                });

                                                resetSubmitButtons();
                                                this.modalInstance.close();
                                            }
                                            else
                                            {
                                                alertAndResetOkButton();
                                                resetSubmitButtons();
                                            }
                                        }.bind(this))
                                        .catch(function (responseData)
                                        {
                                            this.handleException(responseData);
                                            resetSubmitButtons();
                                        }.bind(this));
                                },
                                () => resetSubmitButtons()
                            );
                    }.bind(this);

                    var alertAndResetOkButton = function (message)
                    {
                        simpleAlertService.errorAlert(message);
                        resetSubmitButtons();
                    };

                    this.dateRangeSelected = function ()
                    {
                        var newDateRange =
                        {
                            id: this.newDateRangeId,
                            fromDate: this.formatDate(this.fromDate),
                            toDate: this.formatDate(this.toDate, true),
                            overlapError: false,
                            isToDateLessThan20DaysFromNow: false,
                            isEditable: true,
                        };

                        var validationRequest =
                        {
                            id: this.newDateRangeId,
                            fromDate: this.formatDate(this.fromDate),
                            toDate: this.formatDate(this.toDate, true),
                            dateRanges: []
                        };

                        for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                        {
                            var dateRange = Object.assign({}, this.transportTimetableCalendar.dateRanges[i])

                            validationRequest.dateRanges.push(dateRange);
                        }

                        transportTimetableCalendarsService.validateDateRangeForOverlaps(validationRequest)
                            .then(function (responseDto)
                            {
                                if (responseDto && responseDto.overlapsExist)
                                {
                                    newDateRange.overlapError = true;
                                }

                                this.transportTimetableCalendar.dateRanges.push(newDateRange);
                                this.allDateRangesInPast = !this.transportTimetableCalendar.dateRanges?.some(dr => !dr.isDeleted && moment(dr.fromDate).isAfter(moment().startOf('day')));
                                this.newDateRangeId--;
                                this.fromDate = moment().startOf('day').toDate();
                                this.toDate = moment().endOf('day').toDate();
                                this.addingNewDateRange = false;

                                this.setAnyOverlapsExist();

                            }.bind(this))
                            .catch(function (responseData)
                            {
                                this.handleException(responseData);
                            }.bind(this));
                    }.bind(this);

                    this.dateRangeEdited = function (dateRange)
                    {
                        dateRange.fromDate = this.formatDate(dateRange.fromDate);
                        dateRange.toDate = this.formatDate(dateRange.toDate, true);

                        if (dateRange.id >= 1)
                        {
                            dateRange.isToDateLessThan20DaysFromNow = moment(dateRange.toDate).isBefore(this.todayPlus20Days);
                        }
                        else
                        {
                            dateRange.isToDateLessThan20DaysFromNow = false;
                        }

                        this.dateRangeUnderEditId = 0;
                        this.editingDateRange = false;

                        this.anyToDatesWithin20Days();

                        var validationRequest =
                        {
                            id: dateRange.id,
                            fromDate: dateRange.fromDate,
                            toDate: dateRange.toDate,
                            dateRanges: []
                        };

                        for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                        {
                            var dateRangeToValidate = Object.assign({}, this.transportTimetableCalendar.dateRanges[i])

                            validationRequest.dateRanges.push(dateRangeToValidate);
                        }

                        transportTimetableCalendarsService.validateDateRangeForOverlaps(validationRequest)
                            .then(function (responseDto)
                            {
                                if (responseDto && responseDto.overlapsExist)
                                {
                                    for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                                    {
                                        if (this.transportTimetableCalendar.dateRanges[i].id == dateRange.id)
                                        {
                                            this.transportTimetableCalendar.dateRanges[i].overlapError = true;
                                            break;
                                        }
                                    }
                                }
                                else
                                {
                                    this.transportTimetableCalendar.dateRanges.forEach(function (dateRange)
                                    {
                                        dateRange.overlapError = false;
                                    });
                                }

                                this.fromDate = moment().startOf('day').toDate();
                                this.toDate = moment().endOf('day').toDate();

                                this.setAnyOverlapsExist();

                            }.bind(this))
                            .catch(function (responseData)
                            {
                                this.handleException(responseData);
                            }.bind(this));

                    }.bind(this);

                    this.showAddDateRange = function ()
                    {
                        this.addingNewDateRange = true;

                    }.bind(this);

                    this.deleteDateRange = function (dateRangeToDelete)
                    {
                        this.transportTimetableCalendar.dateRanges =
                            this.transportTimetableCalendar.dateRanges.filter(function (dateRange)
                            {
                                return dateRange.id != dateRangeToDelete.id;
                            });

                        this.allDateRangesInPast = !this.transportTimetableCalendar.dateRanges?.some(dr => !dr.isDeleted && moment(dr.fromDate).isAfter(moment().startOf('day')));
                        if (this.allDateRangesInPast) {
                            this.transportTimetableCalendar.isDefault = false;
                        }
                        this.anyToDatesWithin20Days();
                    }.bind(this);

                    this.editDateRange = function (dateRange)
                    {
                        this.dateRangeUnderEditId = dateRange.id;
                        dateRange.fromDate = moment.utc(dateRange.fromDate);
                        dateRange.toDate = moment.utc(dateRange.toDate);
                        this.editingDateRange = true;

                    }.bind(this);

                    this.cancelAddDateRange = function ()
                    {
                        this.fromDate = moment().startOf('day');
                        this.toDate = moment().endOf('day');
                        this.addingNewDateRange = false;

                    }.bind(this);

                    this.cancelEditDateRange = function (dateRange)
                    {
                        dateRange.fromDate = this.formatDate(dateRange.fromDate);
                        dateRange.toDate = this.formatDate(dateRange.toDate, true);
                        this.dateRangeUnderEditId = 0;
                        this.editingDateRange = false;
                    };

                    this.anyToDatesWithin20Days = function()
                    {
                        for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                        {
                            var dateRange = this.transportTimetableCalendar.dateRanges[i];

                            if (dateRange.id >= 1 &&
                                typeof dateRange.isToDateLessThan20DaysFromNow !== 'undefined' &&
                                dateRange.isToDateLessThan20DaysFromNow)
                            {
                                this.anyInvalidDateRanges = true;
                                return;
                            }
                        }

                        this.anyInvalidDateRanges = false;
                    }.bind(this);

                    this.setAnyOverlapsExist = function ()
                    {
                        for (var i = 0; i < this.transportTimetableCalendar.dateRanges.length; i++)
                        {
                            var dateRange = this.transportTimetableCalendar.dateRanges[i];

                            if (typeof dateRange.overlapError !== 'undefined' &&
                                dateRange.overlapError)
                            {
                                this.anyOverlapsExist = true;
                                return;
                            }
                        }

                        this.anyOverlapsExist = false;
                    }
                }
            ]
        });
