import { downgradeComponent } from "@angular/upgrade/static";
import { ShadowDomComponent } from "@sb-shared/components/shadow-dom/shadow-dom.component";
import * as angular from "angular";

angular.module('diaryModule', [

  'ui.bootstrap',

  'angular.filter',


  'tmh.dynamicLocale',

  'diary.components.diaryConsentEventInvites',
  'diary.components.diaryEventAutoNotify',
  'diary.components.diaryEventDetail',
  'diary.components.diaryEventFormTags',
  'diary.components.diaryEventList',
  'diary.components.diaryEventNotifyOnly',
  'diary.components.diaryEventSendComms',
  'diary.components.diaryEventVideo',
  'diary.services.diaryService',

  'events.components.autoNotifyEvent',
  'events.components.consentEventInvites',
  'events.components.eventDetailEditor',
  'events.components.eventRegister',
  'events.components.notifyOnlyEvent',
  'events.constants',
  'events.services.eventsService',

  'messaging.directives.messageComposer',

  'person.services',

  'reception.services.receptionService',

  'shared.components.sbDatePicker',
  'shared.components.sbDateTimePicker',
  'shared.components.teamEventGuardians',
  'shared.components.teamEventMedical',
  'shared.controllers.confirmationPopup',
  'shared.directives.sbCheckBox',
  'shared.directives.sbRadioButton',
  'shared.directives.updateLanguage',
  // 'shared.directives.cloakTranslate',
  'shared.directives.ngBindHtmlDataLabelAttr',
  'shared.directives.ngBindHtmlPlaceholder',
  'shared.directives.ngBindHtmlTitleAttr',

  'shared.services.arrayService',
  'shared.services.cachedLookupService',
  'shared.services.guidService',
  'shared.services.iconService',
  'shared.services.registerStateService',
  'shared.services.settingService',
  'shared.services.simpleAlertService',
  'shared.services.deviceService',

  'userForm.components.bulkView',
  'ng1StateDefinitionModule'
])
  .config([
    'ng1StateDefinitionProvider',
    function (ng1StateDefinitionProvider) {

      const ng1State = ng1StateDefinitionProvider.$get();

      const moduleName = 'Diary';

      ng1State.state(moduleName, 'specificEvent.notifyOnly',
        {
          url: '/notifyOnly',
        })
      ng1State.state(moduleName, 'specificEvent.consentEventInvites',
        {
          url: '/consentEventInvites',
        })
      ng1State.state(moduleName, 'specificEvent.guardians',
        {
          url: '/guardians',
        })
      ng1State.state(moduleName, 'specificEvent.medical',
        {
          url: '/medical',
        })
      ng1State.state(moduleName, 'specificEvent.formtags',
        {
          url: '/personaldetails',
        })
      ng1State.state(moduleName, 'specificEvent.sendComms',
        {
          url: '/comms',
        });
      ng1State.state(moduleName, 'specificEvent.video',
        {
          url: '/video',
        });
    }
  ])
  .directive('sbShadowDom', downgradeComponent({
    component: ShadowDomComponent,
    inputs: ['trustedHtml']
  }))
  .component('ng1Diary', {
    template: `<diary-event-list person-id='$ctrl.personId'
                                 rows-clickable="true"
                                 highlight-invitations="false"
                                 show-filters="true"
                                 on-row-selected='$ctrl.onRowSelectedCallback'
                                 is-staff='$ctrl.isStaff'
                                 is-parent'$ctrl.isParent'
                                 on-date-clicked='$ctrl.onDateClicked(date)'
                                 on-load-start='$ctrl.onLoadStart()'
                                 on-load-end='$ctrl.onLoadEnd(events)'
                                 on-click-add-event="$ctrl.onClickAddEvent()"></diary-event-list>`,
    bindings: {
      onRowSelected: '&',
      onDateClicked: '&',
      onLoadStart: '&',
      onLoadEnd: '&',
      onClickAddEvent: '& '
    },
    controller: class Ng1DiaryCtrl {
      onRowSelected: any;
      onRowSelectedCallback = (item) => {
        this.onRowSelected(item);
      }
    }
  })
  .component('ng1DiaryEvent', {
    template: `<diary-event-detail ng-if='$ctrl.eventData'
                                   show-title-bar='$ctrl.showTitleBar'
                                   is-staff='$ctrl.isStaff'
                                   is-external='$ctrl.isExternal'
                                   event-data='$ctrl.eventData'
                                   on-back-button-clicked='$ctrl.onBackButtonClickedCallback'></diary-event-detail>`,
    bindings: {
      onBackButtonClicked: '&'
    }
  })
  .component('ng1DiaryEventInfo', {
    template: `<diary-event-detail-info on-back-button-clicked='$ctrl.onBackButtonClicked()'
                                        on-change-request-added='$ctrl.onChangeRequestAdded()'
                                        event-data='$ctrl.eventData'></diary-event-detail-info>`,
    bindings: {
      onBackButtonClicked: '&',
      onChangeRequestAdded: '&',
    }
  })
  .component('ng1DiaryEventEdit', {
    template: `<event-detail-editor event-id="$ctrl.eventId"
                                    on-selected-event-updated='$ctrl.onSelectedEventUpdated()'></event-detail-editor>`,
    bindings: {
      onSelectedEventUpdated: '&',
    }
  })
  .component('ng1DiaryEventRegister', {
    template: `<event-register event-id="$ctrl.eventId"
                               show-title-bar="true"
                               on-register-updated='$ctrl.onRegisterUpdatedCallBack()'
                               is-external='$ctrl.isExternal'
                               is-external-can-view-profile="$ctrl.isExternalCanViewProfile"
                               show-absent-column='$ctrl.showAbsentColumn'
                               is-organisation-admin='$ctrl.isOrganisationAdmin'
                               is-teacher='$ctrl.isTeacher'
                               on-attendees-added-to-event='$ctrl.onAttendeesAddedToEventCallBack()'></event-register>`,
    bindings: {
      onRegisterUpdated: '&',
      onAttendeesAddedToEvent: '&'
    },
    controller: class Ng1DiaryEventRegisterCtrl {

      onAttendeesAddedToEvent: any;
      onRegisterUpdated: any;

      onRegisterUpdatedCallBack() {
        return () => {
          this.onRegisterUpdated()
        };
      }

      onAttendeesAddedToEventCallBack() {
        /* to not break the existed behavior of events-register-component.postPersonChangesToEvent() in old angularjs
         * it invokes the callback method by `this.onAttendeesAddedToEvent()(data.attendants)`
         * so we need to return a inner function to get `data.attendants`
         */
        return attendants => this.onAttendeesAddedToEvent(attendants);
      }
    }
  })
  .component('ng1DiaryEventAutoNotify', {
    bindings: {
      onRefresh: '&'
    },
    template: `<diary-event-auto-notify number-auto-notified="$ctrl.numberAutoNotified" on-refresh="$ctrl.onRefresh()"></diary-event-auto-notify>`,
  })
  .component('ng1DiaryEventNotifyOnly', {
    template: `<diary-event-notify-only event-id="$ctrl.eventId" number-of-future-events-in-series="$ctrl.numberOfFutureEventsInSeries"></diary-event-notify-only>`,
  })
  .component('ng1DiaryEventConsentEventInvites', {
    template: `<diary-consent-event-invites event-id="$ctrl.eventId"></diary-consent-event-invites>`,
  })
  .component('ng1EventConsentEventInvites', {
    template: `<consent-event-invites event-id="$ctrl.eventId" settings-disabled="$ctrl.isSettingsDisabled"></consent-event-invites>`,
  })
  .component('ng1DiaryEventGuardians', {
    template: `<team-event-guardians calendar-event-id="$ctrl.calendarEventId" show-title-bar="true"></team-event-guardians>`,
  })
  .component('ng1DiaryEventMedical', {
    template: `<team-event-medical calendar-event-id="$ctrl.calendarEventId" show-title-bar="true"></team-event-medical>`,
  })
  .component('ng1DiaryEventFormtags', {
    template: `<diary-event-form-tags calendar-event-id="$ctrl.calendarEventId"></diary-event-form-tags>`,
  })
  .component('ng1DiaryEventSendComms', {
    template: `<diary-event-send-comms event-id="$ctrl.eventId" allow-comms="$ctrl.allowComms" on-selected-event-updated="$ctrl.onSelectedEventUpdated()"></diary-event-send-comms>`,
    bindings: {
      onSelectedEventUpdated: '&',
    }
  })
  .component('ng1DiaryEventVideo', {
    bindings: {
      onEndCall: '&'
    },
    template: `<diary-event-video event-details="$ctrl.eventDetails" on-end-call="$ctrl.onEndCall()"></diary-event-video>`,
  })
